import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id      : 'pages',
        title   : '',
        type    : 'group',
        icon    : 'pages',
        children : [
            {
                id   : 'analytics',
                title: 'Dashboard',
                type : 'item',
                icon : 'dashboard',
                url  : '/apps/dashboards/analytics'
            },
            {
                id   : 'todo',
                title: 'Work Orders',
                type : 'item',
                icon : 'list',
                url  : '/apps/work-orders'
            },
            {
                id       : 'create-submit-invoice',
                title    : 'Create & Submit invoice',
                type     : 'collapsable',
                icon     : 'local_offer',
                children : [
                    {
                        id   : 'create-invoice',
                        title: 'Create Invoice',
                        type : 'item',
                        url  : '/apps/create-invoice'
                    },
                    {
                        id   : 'submit-invoice',
                        title: 'Submit Invoice',
                        type : 'item',
                        url  : '/apps/submit-invoice'
                    }
                    
                ]
            },
            {
                id   : 'invoices-and-payments',
                title: 'Invoices And Payments',
                type : 'item',
                icon : 'payment',
                url  : '/apps/invoices-and-payments'
            },
            {
                id   : 'price-guide',
                title: 'Price Guide',
                type : 'item',
                icon : 'import_contacts',
                url  : '/apps/price-guide'
            },
            {
                id   : 'document-upload',
                title: 'Document Upload',
                type : 'item',
                icon : 'history',
                url  : '/apps/document-upload'
            }
        ]    
    }
];
