import { Login,Address,Vendor } from './../_models/login.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<Login>;
    public currentUser: Observable<Login>;


    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<Login>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): Login {
        return this.currentUserSubject.value;
    }

    login(email: string, password: string) {
        
        return this.http.post<any>(`${environment.apiUrl}/auth/vendor/login`, { email, password })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }

    public UpdateUserData(user: Vendor){
        let newUser = this.currentUserValue;
        newUser.vendor.name = user.name;
        newUser.vendor.companyName = user.companyName;
        newUser.vendor.companyAddress = user.companyAddress;
        newUser.vendor.cCity = user.cCity;
        newUser.vendor.cState = user.cState;
        newUser.vendor.cZipCode = user.cZipCode;
        newUser.vendor.mailingAddress = user.mailingAddress;
        newUser.vendor.mCity = user.mCity;
        newUser.vendor.mState = user.mState;
        newUser.vendor.mZipCode = user.mZipCode;
        newUser.vendor.mainPhoneNumber = user.mainPhoneNumber;
        newUser.vendor.emergencyPhoneNumber = user.emergencyPhoneNumber;
        newUser.vendor.dispatchFaxNumber = user.dispatchFaxNumber;
        newUser.vendor.dispatchEmailAddress = user.dispatchEmailAddress;
        newUser.vendor.generalEmailAddress = user.generalEmailAddress;
        newUser.vendor.ownerName = user.ownerName;
        newUser.vendor.contractorsLicenseNumber = user.contractorsLicenseNumber;
        newUser.vendor.taxId = user.taxId;
        newUser.vendor.insuranceCarrierName = user.insuranceCarrierName;
        newUser.vendor.generalLiabilityInsurancePolicyNumber = user.generalLiabilityInsurancePolicyNumber;
        newUser.vendor.weekDayHoursFrom = user.weekDayHoursFrom;
        newUser.vendor.weekDayHoursTo = user.weekDayHoursTo;
        newUser.vendor.saturdayHoursFrom = user.saturdayHoursFrom;
        newUser.vendor.saturdayHoursTo = user.saturdayHoursTo;
        newUser.vendor.sundayHoursFrom = user.sundayHoursFrom;
        newUser.vendor.sundayHoursTo = user.sundayHoursTo;
        newUser.vendor.emergencyHoursWeekdaysFrom = user.emergencyHoursWeekdaysFrom;
        newUser.vendor.emergencyHoursWeekdaysTo = user.emergencyHoursWeekdaysTo;
        newUser.vendor.emergencyHoursWeekendsFrom = user.emergencyHoursWeekendsFrom;
        newUser.vendor.emergencyHoursWeekendsTo = user.emergencyHoursWeekendsTo;
        newUser.vendor.serviceCallFee = user.serviceCallFee;
        newUser.vendor.hourlyLaborRate = user.hourlyLaborRate;
        newUser.vendor.overtimeHourlyRate = user.overtimeHourlyRate;
        newUser.vendor.numberOfServiceTechnicians = user.numberOfServiceTechnicians;
        newUser.vendor.uniformedEmployees = user.uniformedEmployees;
        newUser.vendor.numberOfServiceVehicles = user.numberOfServiceVehicles;
        newUser.vendor.uniformedServiceVehicles = user.uniformedServiceVehicles;
        newUser.vendor.companyName = user.companyName;
        newUser.vendor.airConditioning = user.airConditioning;
        newUser.vendor.heating = user.heating;
        newUser.vendor.plumbing = user.plumbing;
        newUser.vendor.appliances = user.appliances;
        newUser.vendor.electricalSystems = user.electricalSystems;
        newUser.vendor.poolSpa = user.poolSpa;
        newUser.vendor.wellPumps = user.wellPumps;
        newUser.vendor.roofLeakRepair = user.roofLeakRepair;
        newUser.vendor.garbageDisposals = user.garbageDisposals;
        newUser.vendor.sprinklerSystems = user.sprinklerSystems;
        newUser.vendor.garageDoorOpeners = user.garageDoorOpeners;
        newUser.vendor.centralVacuum = user.centralVacuum;
        newUser.vendor.septicSystemsAndPumping = user.septicSystemsAndPumping;
        newUser.vendor.drywall = user.drywall;
        newUser.vendor.wholeCost1_150Regular = user.wholeCost1_150Regular;
        newUser.vendor.wholeCost1_150Thp = user.wholeCost1_150Thp;
        newUser.vendor.wholeCost151_300Regular = user.wholeCost151_300Regular;
        newUser.vendor.wholeCost151_300Thp = user.wholeCost151_300Thp;
        newUser.vendor.wholeCost301_500Regular = user.wholeCost301_500Regular;
        newUser.vendor.wholeCost301_500Thp = user.wholeCost301_500Thp;
        newUser.vendor.wholeCostOver501Regular = user.wholeCostOver501Regular;
        newUser.vendor.wholeCostOver501Thp = user.wholeCostOver501Thp;
        newUser.vendor.salesTaxRate = user.salesTaxRate;
        localStorage.setItem('currentUser', JSON.stringify(newUser));
    }

    Replogin(vendorId: string) {
        return this.http.post<any>(`${environment.apiUrl}/auth/reploginvendorportal?_vendorId=${vendorId}`, vendorId)
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                return user;
            }));
    }
}
